<template>
  <a
    v-if="backUrl"
    id="backLink"
    :href="backUrl"
    target="_blank"
    rel="noopener"
    class="ml-6 mt-2 mb-1"
  >
    <span class="icon-text">
      <span class="icon">
        <fa icon="arrow-to-left" />
      </span>
      <span>{{ backText }}</span>
    </span>
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      backUrl: (state) => state.back_link?.url,
      backText: (state) => state.back_link?.text,
    }),
  },
};
</script>
